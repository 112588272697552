import React from "react"

const Pricing = () => (
    <>
        {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
        <div class="bg-gray-900">
        <div class="pt-12 sm:pt-16 lg:pt-24">
            <div class="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
            <div class="max-w-3xl mx-auto space-y-2 lg:max-w-none">
                <h2 class="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
                    Express Documentation  🏃🏿⏱💨
                </h2>
                <p class="text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
                Need someone to work on your documentation now?
                </p>
                <p class="text-xl text-gray-300">
                    Sign up for our documentation packages. Pick a date, and we'll have your docs done on the same day.
                </p>
            </div>
            </div>
        </div>
        <div class="mt-8 pb-12 bg-gray-50 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
            <div class="relative">
            <div class="absolute inset-0 h-3/4 bg-gray-900"></div>
            <div class="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div class="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
                <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
                    <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                    <div>
                        <h3 class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600" id="tier-standard">
                        Documentation Audit
                        </h3>
                    </div>
                    <div class="mt-4 flex items-baseline text-6xl font-extrabold">
                        $1500
                        {/* <span class="ml-1 text-2xl font-medium text-gray-500">
                        /mo
                        </span> */}
                    </div>
                    <p class="mt-5 text-lg text-gray-500">
                        Want to clean up your docs, but don't know where to start. Schedule a premium audit.
                    </p>
                    </div>
                    <div class="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                    <ul class="space-y-4">
                        <li class="flex items-start">
                        <div class="flex-shrink-0">
                            {/* <!-- Heroicon name: check --> */}
                            <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700">
                            SEO and Documentation Discoverability Audit
                        </p>
                        </li>

                        <li class="flex items-start">
                        <div class="flex-shrink-0">
                            {/* <!-- Heroicon name: check --> */}
                            <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700">
                            Usability Audit
                        </p>
                        </li>

                        <li class="flex items-start">
                        <div class="flex-shrink-0">
                            {/* <!-- Heroicon name: check --> */}
                            <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700">
                            Detailed Report, complete with actionable solutions
                        </p>
                        </li>

                        <li class="flex items-start">
                        <div class="flex-shrink-0">
                            {/* <!-- Heroicon name: check --> */}
                            <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700">
                            One hour phone consultation
                        </p>
                        </li>
                    </ul>
                    <div class="rounded-md shadow">
                        <a href="https://calendly.com/plburton/documentation-audit" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900" aria-describedby="tier-standard">
                        Get started
                        </a>
                    </div>
                    </div>
                </div>

                <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
                    <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                    <div>
                        <h3 class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600" id="tier-standard">
                            Documentation Starter Kit
                        </h3>
                    </div>
                    <div class="mt-4 flex items-baseline text-6xl font-extrabold">
                        $5000
                        {/* <span class="ml-1 text-2xl font-medium text-gray-500">
                        /mo
                        </span> */}
                    </div>
                    <p class="mt-5 text-lg text-gray-500">
                        Perfect for new startups and young tech companies.
                    </p>
                    </div>
                    <div class="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                    <ul class="space-y-4">
                        <li class="flex items-start">
                        <div class="flex-shrink-0">
                            {/* <!-- Heroicon name: check --> */}
                            <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700">
                            Full day documentation online training
                        </p>
                        </li>

                        <li class="flex items-start">
                        <div class="flex-shrink-0">
                            {/* <!-- Heroicon name: check --> */}
                            <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700">
                            We will choose and create a documentation platform for your team
                        </p>
                        </li>

                        <li class="flex items-start">
                        <div class="flex-shrink-0">
                            {/* <!-- Heroicon name: check --> */}
                            <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700">
                            Writer's UI Style Guide
                        </p>
                        </li>

                        <li class="flex items-start">
                        <div class="flex-shrink-0">
                            {/* <!-- Heroicon name: check --> */}
                            <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700">
                            Installation of Privacy-first Analytics
                        </p>
                        </li>
                    </ul>
                    <div class="rounded-md shadow">
                        <a href="https://calendly.com/plburton/documentation-starter-kit" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900" aria-describedby="tier-standard">
                        Get started
                        </a>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="mt-4 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
            {/* <div class="max-w-md mx-auto lg:max-w-5xl">
                <div class="rounded-lg bg-gray-100 px-6 py-8 sm:p-10 lg:flex lg:items-center">
                <div class="flex-1">
                    <div>
                    <h3 class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-white text-gray-800">
                        Discounted
                    </h3>
                    </div>
                    <div class="mt-4 text-lg text-gray-600">Get full access to all of standard license features for solo projects that make less than $20k gross revenue for <span class="font-semibold text-gray-900">$29</span>.</div>
                </div>
                <div class="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
                    <a href="#" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50">
                    Buy Discounted License
                    </a>
                </div>
                </div>
            </div> */}
            </div>
        </div>
        </div>

    </>
)

export default Pricing