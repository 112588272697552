import React from "react";
import { useStaticQuery, graphql } from "gatsby"

const SocialProof = () => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        pastProjects {
                            alt
                            img
                            name
                        }
                    }                
                }         
            }

        `
    )
    const project  = site.siteMetadata.pastProjects
    const proof = project.map(({alt, img}) => (
        <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img class="h-12" src={img} alt={alt} />
        </div>

        )
    )
    return (
        <>
            <h2 className="text-center text-gray-900 leading-7 font-extrabold text-3xl pt-7">Companies We Serve</h2>
            <div class="bg-white">
                <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">  
                    <div class="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-4">
                        {proof}
                    </div>
                </div>
            </div>
        </>
    )

}

export default SocialProof