import React from "react"

const Publications = () => (
    <>
        <div class="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div class="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
            <div>
            <h2 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                Recent publications
            </h2>
            </div>
            <div class="mt-12 grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
            <div>
                <div>
                <a href="https://cecildigitalagency.substack.com/p/how-do-know-this-dev-rel-stuff-is" class="inline-block">
                    <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800">
                    Newsletter
                    </span>
                </a>
                </div>
                <a href="https://cecildigitalagency.substack.com/p/how-do-know-this-dev-rel-stuff-is" class="block mt-4">
                <p class="text-xl font-semibold text-gray-900">
                    How do know this Dev Rel Stuff is Even Working?
                </p>
                <p class="mt-3 text-base text-gray-500">
                Some things are easier to measure. 
                It’s straightforward enough to find out which developer is making the most commits to a project.
                </p>
                </a>
                <div class="mt-6 flex items-center">
                <div class="flex-shrink-0">
                    <a href="https://cecildigitalagency.substack.com/p/how-do-know-this-dev-rel-stuff-is">
                    <span class="sr-only">DocumentWrite</span>
                    <img class="h-10 w-10 rounded-full" src="https://res.cloudinary.com/dmghm3eu4/image/upload/v1611098797/CDA%20Website/cecil_agency_square_logo_q6l05e.png" alt="DocumentWrite Dev Relations" />
                    </a>
                </div>
                <div class="ml-3">
                    {/* <p class="text-sm font-medium text-gray-900">
                    <a href="#">
                        Paul York
                    </a>
                    </p> */}
                    <div class="flex space-x-1 text-sm text-gray-500">
                    <time datetime="2020-03-16">
                        January 13, 2021
                    </time>
                    <span aria-hidden="true">
                        &middot;
                    </span>
                    {/* <span>
                        6 min read
                    </span> */}
                    </div>
                </div>
                </div>
            </div>

            <div>
                <div>
                <a href="https://www.digitalocean.com/community/tutorials/how-to-handle-images-with-graphql-and-the-gatsby-image-api" class="inline-block">
                    <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800">
                    Article
                    </span>
                </a>
                </div>
                <a href="https://www.digitalocean.com/community/tutorials/how-to-handle-images-with-graphql-and-the-gatsby-image-api" class="block mt-4">
                <p class="text-xl font-semibold text-gray-900">
                    How to handle images with GraphQL and Gatsby Image
                </p>
                <p class="mt-3 text-base text-gray-500">
                    Handling images plays a pivotal role in building websites, but also can be challenging to deal with. 
                </p>
                </a>
                <div class="mt-6 flex items-center">
                <div class="flex-shrink-0">
                    <a href="https://www.digitalocean.com/community/tutorials/how-to-handle-images-with-graphql-and-the-gatsby-image-api">
                    <span class="sr-only">DocumentWrite</span>
                    <img class="h-10 w-10 rounded-full" src="https://res.cloudinary.com/dmghm3eu4/image/upload/v1611098797/CDA%20Website/cecil_agency_square_logo_q6l05e.png" alt="GraphQL and Gatsby Image" />
                    </a>
                </div>
                <div class="ml-3">
                    {/* <p class="text-sm font-medium text-gray-900">
                    <a href="#">
                        Dessie Ryan
                    </a>
                    </p> */}
                    <div class="flex space-x-1 text-sm text-gray-500">
                    <time datetime="2020-03-10">
                        January 14, 2021
                    </time>
                    <span aria-hidden="true">
                        &middot;
                    </span>
                    {/* <span>
                        4 min read
                    </span> */}
                    </div>
                </div>
                </div>
            </div>

            <div>
                <div>
                <a href="https://cecildigitalagency.substack.com/p/resolutions" class="inline-block">
                    <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                    Newsletter
                    </span>
                </a>
                </div>
                <a href="https://cecildigitalagency.substack.com/p/resolutions" class="block mt-4">
                <p class="text-xl font-semibold text-gray-900">
                    New Year's Resolutions
                </p>
                <p class="mt-3 text-base text-gray-500">
                    It’s weird making New Year’s resolutions when you build your life around OKRs, sprints, and 90-day goals.
                </p>
                </a>
                <div class="mt-6 flex items-center">
                <div class="flex-shrink-0">
                    <a href="https://cecildigitalagency.substack.com/p/resolutions">
                    <span class="sr-only">DocumentWrite</span>
                    <img class="h-10 w-10 rounded-full" src="https://res.cloudinary.com/dmghm3eu4/image/upload/v1611098797/CDA%20Website/cecil_agency_square_logo_q6l05e.png" alt="DocumentWrite New Year's Resolutions" />
                    </a>
                </div>
                <div class="ml-3">
                    {/* <p class="text-sm font-medium text-gray-900">
                    <a href="#">
                        Easer Collins
                    </a>
                    </p> */}
                    <div class="flex space-x-1 text-sm text-gray-500">
                    <time datetime="2020-02-12">
                        January 6, 2021
                    </time>
                    <span aria-hidden="true">
                        &middot;
                    </span>
                    <span>
                        11 min read
                    </span>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </>
)

export default Publications