import React from "react"
import { useStaticQuery, graphql } from "gatsby" 

const Features = () => {
    // const { site } = useStaticQuery(
    //     graphql`
    //         query {
    //             site {
    //                 siteMetadata {
    //                     services {
    //                         blurb
    //                     }
    //                 }
    //             }
    //         }
    //     `
    // )

    // const serviceSection = site.siteMetadata.services.blurb
    // console.log(serviceSection)


    return (
        <>
            {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
            <div class="py-16 bg-gray-50 overflow-hidden lg:py-24">
                <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                    <svg class="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
                    <defs>
                        <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
                    </svg>

                    <div class="relative">
                        <h2 class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            Our Services
                        </h2>
                    </div>

                    <div class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                    <div class="relative">
                        <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                        Let us build your documentation
                        </h3>
                        <p class="mt-3 text-lg text-gray-500">
                        We are here to help you build your technical knowledge center. Ask us about our docs-as-code workflow.
                        </p>

                        <dl class="mt-10 space-y-10">
                        <div class="flex">
                            <div class="flex-shrink-0">
                            <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                {/* Heroicon name: globe-alt  */}
                                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                                </svg>
                            </div>
                            </div>
                            <div class="ml-4">
                            <dt class="text-lg leading-6 font-medium text-gray-900">
                                Living Documentation
                            </dt>
                            <dd class="mt-2 text-base text-gray-500">
                                Your product is always improving. So should your documentation. Enjoy docs that are up-to-date with your code. 
                            </dd>
                            </div>
                        </div>

                        <div class="flex">
                            <div class="flex-shrink-0">
                            <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                {/* Heroicon name: scale */}
                                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                                </svg>
                            </div>
                            </div>
                            <div class="ml-4">
                            <dt class="text-lg leading-6 font-medium text-gray-900">
                                Diverse Expertise
                            </dt>
                            <dd class="mt-2 text-base text-gray-500">
                                Our writers come from all walks of life. We have developers and social media experts here to ensure that your docs are well-written and distributed widely. 
                            </dd>
                            </div>
                        </div>

                        <div class="flex">
                            <div class="flex-shrink-0">
                            <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                {/* Heroicon name: lightning-bolt */}
                                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                                </svg>
                            </div>
                            </div>
                            <div class="ml-4">
                            <dt class="text-lg leading-6 font-medium text-gray-900">
                                'Getting Started Guides' are Our Specialty
                            </dt>
                            <dd class="mt-2 text-base text-gray-500">
                                We believe in prioritizing the beginner. Beginner-friendly docs are the best advocate for your product.
                            </dd>
                            </div>
                        </div>
                        </dl>
                    </div>

                    <div class="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                        <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
                        <defs>
                            <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
                        </svg>
                        <img class="relative mx-auto" width="490" src="https://res.cloudinary.com/dmghm3eu4/image/upload/v1609259657/Build-documentation-cecil-agency_gycalr.png" alt="" />
                    </div>
                    </div>

                    <svg class="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
                    <defs>
                        <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
                    </svg>

                    <div class="relative mt-12 sm:mt-16 lg:mt-24">
                    <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                        <div class="lg:col-start-2">
                        <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                            Learn how to improve your docs
                        </h3>
                        <p class="mt-3 text-lg text-gray-500">
                            Want to learn how to improve your documentation? Join us for our classes and webinars!
                        </p>

                        <dl class="mt-10 space-y-10">
                            <div class="flex">
                            <div class="flex-shrink-0">
                                <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                {/* Heroicon name: annotation */}
                                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                                </svg>
                                </div>
                            </div>
                            <div class="ml-4">
                                <dt class="text-lg leading-6 font-medium text-gray-900">
                                    Free Webinars
                                </dt>
                                <dd class="mt-2 text-base text-gray-500">
                                    Several times a year we teach docs-as-code courses. Sign up below if you are intersted in learning more about static site generators, git, and more!
                                </dd>
                            </div>
                            </div>

                            <div class="flex">
                            <div class="flex-shrink-0">
                                <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                {/* Heroicon name: mail */}
                                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                </svg>
                                </div>
                            </div>
                            <div class="ml-4">
                                <dt class="text-lg leading-6 font-medium text-gray-900">
                                    In-house Coaching
                                </dt>
                                <dd class="mt-2 text-base text-gray-500">
                                    Want to change the documentation culture at your company? We offer one-day seminars where we teach actionable technical writing strategies.
                                </dd>
                            </div>
                            </div>
                        </dl>
                        </div>

                        <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                        <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404" aria-hidden="true">
                            <defs>
                            <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                            </pattern>
                            </defs>
                            <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
                        </svg>
                        <img class="relative mx-auto" width="490" src="https://res.cloudinary.com/dmghm3eu4/image/upload/v1609259645/cecil-digital-agency-classes_u1owgv.png" alt="" />
                        </div>
                    </div>
                    </div>
                </div>
            </div>

        </>
        )
}

export default Features