import React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Investors from "../components/investors"
import Features from "../components/features"
import Pricing from "../components/pricing"
import Process from "../components/ourProcess"
import Publications from "../components/publications"
import SocialProof from "../components/SocialProof"



const IndexPage = () => (
  <Layout>
    <Hero />
    <SocialProof />
    <Features />
    <Investors />
    <Process />
    <Pricing />
    <Publications />
  </Layout>
)

export default IndexPage
